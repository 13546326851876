import React from 'react'
import { Link } from "react-router-dom"
import "./DropdownButton.css"

const DropdownButton = ({ name, type, options, setShowPrivacyModal, isExternal }) => {

  return (
    <div id="dropdown-container">
      <button className={"menu-button " + type}>{name}</button>
      <div className="dropdown-gap"></div>
      <ul className={"dropdown-menu dropdown-" + type}>
        {options.map((option, index) => (
          <li key={index}>
            {option.page !== undefined && option.page.startsWith("http") ? (<a href={option.page}>{option.label}</a>)
              : option.modal !== undefined ? (<a onClick={() => setShowPrivacyModal(true)}>{option.label}</a>) :
                <Link to={option.page}>{option.label}</Link>
            }
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DropdownButton
