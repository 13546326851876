import { React, useEffect } from "react";
import LoginForm from "./components/pages/LoginForm/LoginForm";
import Portal from "./components/pages/Portal/Portal";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./index.css";
import Extensions from "./components/pages/Extensions+Skins/Extensions";
import { ProtectedRoute, PublicRoute } from "./Utils/AuthRoutes";

import ResetPass from "./components/pages/ResetPass/ResetPass";
import { getToken, removeUserSession, setUserSession } from "./Utils/Common";
import axios from "axios";
import { TitleComponent } from "./components/Other/TitleComponent";
import Page_404 from "./components/pages/404/404";
// import auth from "./auth";

const App = () => {
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    console.log("location changed");
    const token = getToken();
    if (!token) return;

    axios
      .post(`/auth/verifyToken?token=${token}`)
      .then((response) => {
        setUserSession(response.data.token, response.data.email);
      })
      .catch((error) => {
        removeUserSession(history);
        console.error(error);
      });
  }, [location]);

  return (
    <div className="App">
      <TitleComponent title="MyWikis Self Service" />
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <PublicRoute exact path="/login" component={LoginForm} />
        <ProtectedRoute exact path="/portal" component={Portal} />
        <ProtectedRoute
          exact
          path="/:wikiid/extensions"
          component={() => <Extensions page="extensions" />}
        />
        <ProtectedRoute
          exact
          path="/:wikiid/configs"
          component={() => <Extensions page="configs" />}
        />
        <ProtectedRoute
          exact
          path="/:wikiid/skins"
          component={() => <Extensions page="skins" />}
        />
        <ProtectedRoute
          exact
          path="/:wikiid/privacy"
          component={() => <Extensions page="privacy" />}
        />

        <Route
          exact
          path="/:wikiid/resetpass"
          component={() => <ResetPass name="MyWikis Testing" id="testing" />}
        />

        <Route path="*" component={Page_404} />
      </Switch>
    </div>
  );
};

export default App;
