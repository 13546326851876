import "./WikiCard.css"
import DropdownButton from "./DropdownButton/DropdownButton"
import React from "react"

const WikiCard = ({ wikiName, wikiID, plan, productID, setShowPrivacyModal }) => {
  const manageOptions = [
    {
      label: "Change plan",
      page: `https://${process.env.REACT_APP_CLIENT_PANEL_DOMAIN ?? "panel.mywikis.com"}/upgrade.php?type=package&id=${productID}`,
    },
    {
      label: "Change privacy level",
      page: `${wikiID}/privacy`,
    },
    {
      label: "Plan addons",
      page: `https://${process.env.REACT_APP_CLIENT_PANEL_DOMAIN ?? "panel.mywikis.com"}/upgrade.php?type=configoptions&id=${productID}`,
    },
    {
      label: "Wiki extensions",
      page: `${wikiID}/extensions`,
    },
    {
      label: "Wiki skins",
      page: `${wikiID}/skins`,
    },
    {
      label: "Wiki configurations",
      page: `${wikiID}/configs`,
    },
    {
      label: "Get support",
      page: `https://www.${process.env.REACT_APP_HOMEPAGE_DOMAIN ?? "mywikis.com"}/contact`,
    },
  ];
  const adminsterOptions = [
    {
      label: "Add or remove wiki admins",
      page: `https://${wikiID}.${process.env.REACT_APP_WIKI_DOMAIN ?? "mywikis.wiki"}/wiki/Special:UserRights`,
    },
    {
      label: "Block a wiki user",
      page: `https://${wikiID}.${process.env.REACT_APP_WIKI_DOMAIN ?? "mywikis.wiki"}/wiki/Special:BlockUser`,
    },
    {
      label: "Reset a wiki user's password",
      page: `${wikiID}/resetpass`,
    },
    {
      label: "Access on-wiki self service tools",
      page: `https://${wikiID}.${process.env.REACT_APP_WIKI_DOMAIN ?? "mywikis.wiki"}/wiki/Special:SelfService`,
    }
  ];
  return (
    <div className="card-container">
      <div className="card-content">
        <div className="first-row">
          <h1 className="wiki-name">{wikiName}</h1>
          <h4 className={"plan " + plan}>{plan}</h4>
        </div>
        <h3 className="wiki-id">{wikiID}</h3>
        <div className="card-buttons">
          <DropdownButton
            name="Manage Settings"
            type="manage-settings"
            options={manageOptions}
            setShowPrivacyModal={setShowPrivacyModal}
          />
          <DropdownButton
            name="Administer Wiki"
            type="administer-wiki"
            options={adminsterOptions}
          />
        </div>
      </div>
    </div>
  )
}

export default WikiCard
