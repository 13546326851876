import "./Extensions.css";
import React, { useRef } from "react";
import { Icon } from "@iconify/react";
import skinOutlined from "@iconify/icons-ant-design/skin-outlined";
import { useState } from "react";
import puzzlePiece24Regular from "@iconify/icons-fluent/puzzle-piece-24-regular";
import ExtensionContainer from "./Extensions/ExtensionContainer";
import SkinsContainer from "./Skins/SkinsContainer";
import { BiCog } from "react-icons/bi";
import keyIcon from '@iconify/icons-bi/key';
import FadeIn from "react-fade-in";
import ConfigsContainer from "./Configs/ConfigsContainer";
import PrivacyContainer from "./Privacy/PrivacyContainer";
import Navbar from "../../Navbar/Navbar";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { getToken } from "../../../Utils/Common";
import { SpinnerWithLogo } from "../../WikisSpinner/WikisSpinner";

const Extensions = ({ page }) => {
  const [pageOption, setPageOption] = useState(page);
  const [loading, setloading] = useState(true);
  const [wikiname, setwikiname] = useState("");
  const [wikiplan, setwikiplan] = useState("");
  const { wikiid } = useParams();

  const [alldata, setalldata] = useState({});

  const [enabledExtensions, setEnabledExtensions] = useState([]);
  const [enabledSkins, setEnabledSkins] = useState([]);
  const [enabledConfigs, setEnabledConfigs] = useState([]);
  const [defaultSkin, setDefaultSkin] = useState('vector');
  const [defaultLanguage, setDefaultLanguage] = useState('en');
  const [languages, setLanguages] = useState({});
  const [privacyData, setPrivacyData] = useState({});
  const [privacyLevel, setPrivacyLevel] = useState('Not Found');

  const [initialConfigs, setInitialConfigs] = useState([]);

  let history = useHistory();

  useEffect(() => {
    axios
      .all([
        axios.get(`wikis/${wikiid}/name`),
        axios.get(`wikis/${wikiid}/plan`),
        axios.get(`extensions/`),
        axios.get(`skins/`),
        axios.get(`customconfigs/`),
        axios.get(`wikis/${wikiid}/extensions`),
        axios.get(`wikis/${wikiid}/skins`),
        axios.get(`wikis/${wikiid}/configs`),
        axios.get(`wikis/${wikiid}/default-skin`),
        axios.get(`wikis/${wikiid}/default-language`),
        axios.get(`languages/`),
        axios.get(`privacy/`),
        axios.get(`wikis/${wikiid}/privacy-level`),
      ])
      .then(
        axios.spread(
          (
            wikinameRes,
            wikiplanRes,
            extensionsRes,
            skinsRes,
            configsRes,
            installedExtRes,
            installedSkinRes,
            installedConfigRes,
            defaultSkinRes,
            defaultLanguageRes,
            languagesRes,
            privacyRes,
            privacyLevelRes
          ) => {
            setwikiname(wikinameRes.data);

            setwikiplan(wikiplanRes.data);

            setalldata({
              extensions: extensionsRes.data,
              skins: skinsRes.data,
              configurations: configsRes.data,
            });

            setEnabledExtensions(installedExtRes.data);
            setEnabledSkins(installedSkinRes.data);
            setDefaultSkin(defaultSkinRes.data);
            setEnabledConfigs(installedConfigRes.data);
            setInitialConfigs(installedConfigRes.data);

            setDefaultLanguage(defaultLanguageRes.data);
            setLanguages(languagesRes.data);
            
            setPrivacyData(privacyRes.data);
            setPrivacyLevel(privacyLevelRes.data);

            setloading(false);

          }
        )
      )
      .catch((error) => {
          if (error.response.status === 404) {
            history.push("/portal");
          } else window.location.reload();
        });
  }, []);

  useEffect(() => {
    window.history.pushState("", "", "/" + wikiid + "/" + pageOption);
  }, [pageOption]);

  if (loading) return <SpinnerWithLogo />;

  return (
    <>
      <Navbar />
      <FadeIn>
        <div className="name-id-container">
          <h1>{wikiname}</h1>
          <span>{wikiid}</span>
        </div>
        <div className="extensions-skins-container">
          <div className="left-options-container">
            <button
              className={
                pageOption === "extensions"
                  ? "page-option enabled"
                  : "page-option"
              }
              onClick={() => setPageOption("extensions")}
            >
              <Icon icon={puzzlePiece24Regular} />
              Extensions
            </button>
            <button
              className={
                pageOption === "skins" ? "page-option enabled" : "page-option"
              }
              onClick={() => setPageOption("skins")}
            >
              <Icon icon={skinOutlined} style={{ fontSize: "24px" }} />
              Skins
            </button>
            <button
              className={
                pageOption === "configs" ? "page-option enabled" : "page-option"
              }
              onClick={() => setPageOption("configs")}
            >
              <BiCog style={{ fontSize: "24px" }} />
              Configs
            </button>
            <button
              className={
                pageOption === "privacy"
                  ? "page-option enabled"
                  : "page-option"
              }
              onClick={() => setPageOption("privacy")}
            >
              <Icon icon={keyIcon} />
              Privacy
            </button>
          </div>

          <div className="rightContainer">
            {pageOption === "extensions" && (
              <ExtensionContainer
                wikiid={wikiid}
                wikiplan={wikiplan}
                totalExtensions={alldata.extensions}
                enabledExtensions={enabledExtensions}
                setEnabledExtensions={setEnabledExtensions}
              />
            )}
            {pageOption === "skins" && (
              <SkinsContainer
                wikiid={wikiid}
                totalSkins={alldata.skins}
                enabledSkins={enabledSkins}
                setEnabledSkins={setEnabledSkins}
                defaultSkin={defaultSkin}
                setDefaultSkin={setDefaultSkin}
              />
            )}
            {pageOption === "configs" && (
              <ConfigsContainer
                wikiid={wikiid}
                configs={alldata.configurations}
                enabledConfigs={enabledConfigs}
                setEnabledConfigs={setEnabledConfigs}
                initialConfigs={initialConfigs}
                setInitialConfigs={setInitialConfigs}
                defaultLanguage={defaultLanguage}
                setDefaultLanguage={setDefaultLanguage}
                languages={languages}
              />
            )}
            {pageOption === "privacy" && (
              <PrivacyContainer
                wikiid={wikiid}
                privacyData={privacyData}
                privacyLevel={privacyLevel}
                setPrivacyLevel={setPrivacyLevel}
              />
            )}
          </div>
        </div>
        <footer>
          <p className="copyright-text">© 2020-{new Date().getFullYear()} MyWikis LLC.</p>
        </footer>
      </FadeIn>
    </>
  );
};

export default Extensions;
