import ConfigCard from "./ConfigCard/ConfigCard";
import DropdownDefaultLanguage from "./ConfigCard/DropdownDefaultLanguage";
import "./ConfigsContainer.css";
import React, { useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import axios from "axios";
import ChangesLoading from "../../../Other/ChangesLoading";
import {
  SuccessNotif,
  ErrorNotif,
} from "../../../../Utils/FeedbackHandler/FeedbackHandler";
import { Fade } from "@material-ui/core";

const ConfigsContainer = ({
  wikiid,
  configs,
  enabledConfigs,
  setEnabledConfigs,
  initialConfigs,
  setInitialConfigs,
  defaultLanguage,
  setDefaultLanguage,
  languages
}) => {
  const [applyLoading, setapplyLoading] = useState(false);
  const [changesMade, setchangesMade] = useState(false);
  const [unappliedchanges, setunappliedchanges] = useState(false);
  const [error, seterror] = useState("");

  function changeDefaultLanguage(default_language) {
    seterror("");
    setapplyLoading(true);
    axios
      .put(`wikis/${wikiid}/default-language/${default_language}`, "")
      .then((response) => {
        if (response.status === 204) {
          setDefaultLanguage(default_language);
        } else {
          seterror("Couldn't update the default language. Please try again.");
        }
        setapplyLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setapplyLoading(false);
        if (error.response.status === "401") {
          window.location.reload();
        } else {
          seterror("Something went wrong. Please try again.");
        }
      });
  }

  // removes or adds name to list on toggle
  const toggleCheckbox = (name) => {
    setchangesMade(false);
    seterror("");
    const index = enabledConfigs.indexOf(name);
    // item already exists, remove it
    if (index !== -1) {
      const temp_installed_configs = [...enabledConfigs];
      temp_installed_configs.splice(index, 1);
      setEnabledConfigs(temp_installed_configs);
    } else {
      // add item to list
      setEnabledConfigs([...enabledConfigs, name]);
    }
  };

  const applyChanges = async () => {
    setapplyLoading(true);
    const addedconfigs = enabledConfigs.filter(
      (x) => initialConfigs.indexOf(x) === -1
    );

    const removedconfigs = initialConfigs.filter(
      (x) => enabledConfigs.indexOf(x) === -1
    );

    let promises = [];

    addedconfigs.forEach((config) => {
      promises.push(axios.post(`wikis/${wikiid}/configs/${config}`, ""));
    });

    removedconfigs.forEach((config) => {
      promises.push(axios.delete(`wikis/${wikiid}/configs/${config}`));
    });

    Promise.all(promises)
      .then((results) => {
        setInitialConfigs([...enabledConfigs]);
        setapplyLoading(false);
        if (promises.length !== 0) {
          setchangesMade(true);
        }
      })
      .catch((error) => {
        setapplyLoading(false);
        if (error.response.status === "401") window.location.reload();
        else seterror("Something went wrong. Please try again.");
      });
  };

  const resetConfigs = () => {
    setEnabledConfigs([...initialConfigs]);
  };

  useEffect(() => {
    setunappliedchanges(
      enabledConfigs.sort().join(",") !== initialConfigs.sort().join(",")
    );

    // console.log("enabled", enabledConfigs);
    // console.log("initial", initialConfigs);
  }, [enabledConfigs, initialConfigs]);

  return (
    <FadeIn>
      {applyLoading && <ChangesLoading />}
      <div className="configs-container">
        <div className="language-wrapper">
          <span>Select Your Default Language</span>
          <p>
            The default language will be displayed to users who are not signed in,{" "}
            as well as to logged-in users who haven't changed their language preferences.
          </p>
          <DropdownDefaultLanguage
            type="default-language"
            changeDefaultLanguage={changeDefaultLanguage}
            defaultLanguage={defaultLanguage}
            languagesData={languages.languages_data}
          />
        </div>
        <br></br>
        {changesMade && (
          <SuccessNotif
            message={`Your changes have been successfully applied.`}
            width="70"
          />
        )}
        {error && <ErrorNotif error={error} />}
        <span>Manage Your Configurations</span>
        <p>
          If the configuration setting you wish to add to your wiki is not an
          option below, <a href="https://www.mywikis.com/contact">please contact our support team.</a>{" "}
          They will assist you with making the change.
        </p>
        <div className="configs-wrapper">
          {configs.customconfigs_list.map((config, index) => (
            <ConfigCard
              pName={configs.customconfigs_data[config].pretty_name}
              name={config}
              key={index}
              icon={configs.customconfigs_data[config].pretty_name}
              enabledConfigs={enabledConfigs}
              enabled={enabledConfigs.indexOf(config) > -1}
              handleCheckboxChange={toggleCheckbox}
            />
          ))}
          {unappliedchanges && <UnappliedChangesNotif />}
          <div className="apply-changes-button-wrapper">
            <div className="button-and-reset">
              <button onClick={applyChanges}>Apply Changes</button>
              {unappliedchanges && (
                <i onClick={resetConfigs} className="fas fa-undo"></i>
              )}
            </div>
          </div>
        </div>
      </div>
    </FadeIn>
  );
};

const UnappliedChangesNotif = () => (
  <FadeIn>
    <div className="unapplied-changes-wrapper">
      <span>You currently have unapplied changes</span>
    </div>
  </FadeIn>
);
export default ConfigsContainer;
