import axios from "axios"
import React, { useState } from "react"
import FadeIn from "react-fade-in"
import { ErrorNotif } from "../../../../Utils/FeedbackHandler/FeedbackHandler"
import ChangesLoading from "../../../Other/ChangesLoading"
import "./PrivacyContainer.css"

const PrivacyContainer = ({
    wikiid,
    privacyData,
    privacyLevel,
    setPrivacyLevel
  }) => {
    const [applyLoading, setapplyLoading] = useState(false)
    const [privacyError, setPrivacyError] = useState("")

    // List of fields to display for each wikiplan
    const criteria = ["read", "edit", "create_account"]

    // Mapping of each key to its pretty name
    const pretty_keys = {
        "read": "Read",
        "edit": "Edit",
        "create_account": "Create Account"
    }

    function changePrivacyLevel(level) {
        setPrivacyError("")
        setapplyLoading(true)
        axios
            .put(`wikis/${wikiid}/privacy-level/${level}`, "")
            .then((response) => {
                if (response.status === 204) {
                    setPrivacyLevel(level)
                } else {
                    setPrivacyError("Couldn't update privacy level. Please try again.")
                }
                setapplyLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setapplyLoading(false)
                if (error.response.status === "401") {
                    window.location.reload()
                } else {
                    setPrivacyError("Something went wrong. Please try again.")
                }
            })
    }

    return (
      <FadeIn>
        {applyLoading && <ChangesLoading />}
        <div className="configs-container">
            <div className="current-privacy">
                <span>Current Level: {privacyData['privacy_data'][privacyLevel]['pretty_name']}</span>
            </div>

            <br></br>

            <div className="privacy-span">
                <span>Select Your Privacy Level</span>
                <p>
                Your privacy level determines who can access your wiki.{" "}
                You can always change your privacy level later.
                </p>
            </div>

            <div className="privacy-table-container">
				<div className="plans-table-container">
                    {privacyError && <ErrorNotif error={privacyError} />}
					<div className="plans-table-row privacy-header-row">
						<div className="plans-table-header-cell cell-hide">
							<div className="text-cell left-cell">
                                Privacy Level
							</div>
						</div>
						{criteria.map((field, index) => (
							<div key={index} className="plans-table-header-cell">
								<div className="text-cell">{pretty_keys[field]}</div>
							</div>
						))}
					</div>
					{privacyData["privacy_list"].map((level, ind) => (
						<React.Fragment key={ind}>
							<div className="plans-table-row">
								<div className="plans-table-cell name-cell set-privacy-div" id="feature-name-inline">
                                    {level !== privacyLevel ? (
                                        <button className="set-privacy-button" onClick={() => changePrivacyLevel(level)}>
                                            {privacyData["privacy_data"][level]["pretty_name"]}
                                        </button>
                                    ) : (
                                        <button className="current-privacy-button" disabled>
                                            {privacyData["privacy_data"][level]["pretty_name"]}
                                        </button>
                                    )
                                    }
								</div>
								{criteria.map((field, index) => (
									<div
										key={index}
										className={
											privacyData['privacy_data'][level]['pretty_fields'][field] === "Everyone"
												? "plans-table-cell text-cell everyone"
												: privacyData['privacy_data'][level]['pretty_fields'][field] === "Users Only"
												? "plans-table-cell text-cell usersonly"
												: "plans-table-cell text-cell adminapprove"
										}
									>
                                    <span className="cell-text">
                                        {privacyData['privacy_data'][level]['pretty_fields'][field]}
                                    </span>
									</div>
								))}
							</div>
						</React.Fragment>
					))}
				</div>
          </div>

        </div>
      </FadeIn>
    )
  }

  export default PrivacyContainer