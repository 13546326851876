import "./404.css";
import logo from "../../../../src/logo.svg";
import { Link } from "react-router-dom";

const Page_404 = () => {
  return (
    <div className="container-404">
      <img src={logo} alt="MyWikis logo" />
      <h1>404</h1>
      <h3>Page not found</h3>
      <p>
        Sorry, the page you requested could not be found. Please make sure you
        have typed the correct URL.
      </p>
      <div className="buttons-404">
        <Link to="/login" className="login-button-404">
          Login
        </Link>
        <Link to="/" className="contact-button-404">
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default Page_404;
