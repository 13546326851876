import arrowDownCircleFill from "@iconify/icons-bi/arrow-down-circle-fill";
import circleCheckFill from "@iconify/icons-bi/check-circle-fill";
import questionCircle from "@iconify/icons-bi/question-circle-fill";
import { Icon } from "@iconify/react";
import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./SkinCard.css";

import { BiTrash } from "react-icons/bi";

const SkinCard = ({ pName, name, enabled, isPreinstalled, addSkin, deleteSkin, openLink}) => {
  return (
    <div
      className={
        enabled || isPreinstalled
          ? "skin-card skin-card-installed"
          : "skin-card skin-card-uninstalled"
      }
    >
      <span>{pName}</span>
      <div className="icon-buttons">
        {!enabled && !isPreinstalled ? (
            <Icon
              icon={arrowDownCircleFill}
              style={{ color: "#5b5b5b", fontSize: "20px" }}
              onClick={() => addSkin(name)}
            />
          )
          : (
            <Icon
                icon={circleCheckFill}
                style={{ color: "#ffffff", fontSize: "20px", cursor: "default" }}
            />
          )
        }
        <Icon
          icon={questionCircle}
          style={{ fontSize: "20px"}}
          onClick={() => openLink(pName)}
        />
      </div>
      {enabled && (
        <button
          className="skin-delete-btn"
          onClick={() => deleteSkin(name)}
        >
          <BiTrash size="12px" />
        </button>
      )}
    </div>
  );
};

export default SkinCard;
