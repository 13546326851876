import "./ExtensionCard.css";
import { BiTrash } from "react-icons/bi";
import { BiInfoCircle } from "react-icons/bi";
import {BiEdit} from "react-icons/bi"
import React from "react";
import FadeIn from "react-fade-in";
import ExtensionIcon from "../ExtensionIcon/ExtensionIcon";

const ExtensionCard = ({
  deleteExtension,
  name,
  icon,
  description,
  setExtName,
  settings, 
  setShowFieldsModal, 
  getExtSettings,
  openLink,
  generateExtensionMWOPage
}) => {
  return (
    <FadeIn>
      <div className="extension-card">
        {/* <button
          className="ext-link-button"
          onClick={() => props.openLink(props.name)}
        >
          <BiInfoCircle />
        </button> */}

        <div className="card-details-container">
          <ExtensionIcon icon_raw={icon} />
          <div className="extension-info">
            <h4>{name} <a href={generateExtensionMWOPage(name)} target="_blank" rel="noreferrer"><BiInfoCircle /></a></h4>
            <p>{description}</p>
          </div>
        </div>

        {Object.keys(settings).length !== 0 && 
          <button onClick={() => [getExtSettings(name), document.body.classList.add('active-modal'), setExtName(name)]} 
          className="ext-fields-button">
            <BiEdit/>
          </button>
        }

        <button
          className="ext-delete-button"
          onClick={() => deleteExtension(name)}
        >
          <BiTrash />
        </button>
      </div>
    </FadeIn>
  );
};

export default ExtensionCard;
