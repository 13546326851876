import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Navbar.css";
import logo from "../../logo.svg";
import { removeUserSession } from "../../Utils/Common";
import { getUser } from "../../Utils/Common";

const Navbar = (props) => {
  const [click, setClick] = useState(false);
  const [email, setemail] = useState("");

  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => setClick(false);

  let history = useHistory();

  const handleLogout = () => {
    removeUserSession(history);
  };

  useEffect(() => {
    setemail(getUser());
  }, []);

  return (
    <nav className="navbar">
      <Link to="/portal" className="navbar-logo">
        <img src={logo} alt="" />
        <h1>MyWikis Self Service</h1>
      </Link>

      <div className="menu-toggle" onClick={handleClick}>
        <i className={click ? "fas fa-times" : "fas fa-bars"} />
      </div>
      <div className={click ? "menu-items" : "menu-items collapsed"}>
        <div className="nav-links">
          <Link to="/portal" className="nav-item" onClick={closeMobileMenu}>
            Home
          </Link>
          <a
            className="nav-item"
            href="https://panel.mywikis.com/clientarea.php"
          >
            Client Panel
          </a>
          <a
            className="nav-item"
            href="https://helpcenter.mywikis.wiki/"
          >
            Help Center
          </a>
        </div>
        <div className="rightSide">
          <Link to="#" className="email" onClick={closeMobileMenu}>
            {email}
          </Link>
          <button className="logout btn41-43 btn-41" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
