import SyncLoader from "react-spinners/SyncLoader";
import ClipLoader from "react-spinners/ClipLoader";
import React from "react";

import "./WikisSpinner.css";
import logo from "../../logo.svg";

export const SpinnerWithLogo = () => {
  return (
    <div className="wikis-spinner-container">
      <img src={logo} alt="MyWikis logo" />
      <SyncLoader color={"#51b7f5"} />
    </div>
  );
};

export const WikisSpinner = () => {
  return (
    <div className="wikis-spinner-container">
      <SyncLoader color={"#51b7f5"} />
    </div>
  );
};

export const ClipSpinner = () => {
  return (
    <div className="wikis-spinner-container">
      <ClipLoader color={"#51b7f5"} size={50} />
    </div>
  );
};
