import "./ExtensionCardAV.css";
import { BiInfoCircle } from "react-icons/bi";
import React, { useLayoutEffect, useState } from "react";
import ExtensionIcon from "../ExtensionIcon/ExtensionIcon";

const ExtensionCardAV = ({
  enabled,
  addExtension,
  name,
  pName,
  icon,
  settings,
  description,
  setExtName, 
  setShowFieldsModal,
  getExtSettings,
  openLink,
  generateExtensionMWOPage
}) => {
  const addExtensionCard = () => {
    if (!enabled) {
      addExtension(name);
    }
  };

  // keep track of window width
  const [width, setwidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  function addButtonPopup() {
    let isRequired = false
    Object.keys(settings).map((setting, index) => 
      settings[setting].required == true && (
        isRequired = true
      )
    )
    if (isRequired) {
      getExtSettings(name)
      setExtName(name)
      document.body.classList.add('active-modal')
      addExtensionCard(this)
    } else {
      addExtensionCard(this)
    }
  }

  return (
    <div className="extension-card-av">

      {/* <button
        className="ext-link-button"
        onClick={() => openLink(name)}
      >
        <BiInfoCircle />
      </button> */}

      <div className="card-details-container">
        {/* <img src="" alt="M" /> */}
        <button
          onClick={Object.keys(settings).length !== 0 
                    ? addButtonPopup  :
                    addExtensionCard
          }
          className={enabled ? "ext-installed" : "ext-add"}
        >
          {enabled
            ? width > 500
              ? "Installed"
              : "✓"
            : width > 500
            ? "+ Add"
            : "+"}
        </button>
        <ExtensionIcon icon_raw={icon} />
        <div className="extension-info">
          <h4>{pName} <a href={generateExtensionMWOPage(name)} target="_blank" rel="noreferrer"><BiInfoCircle /></a></h4>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ExtensionCardAV;