import SkinCard from "./SkinCard/SkinCard"
import DropdownDefaultSkin from "./SkinCard/DropdownDefaultSkin"
import "./SkinsContainer.css"
import React, { useState } from "react"
import FadeIn from "react-fade-in"
import axios from "axios"
import ChangesLoading from "../../../Other/ChangesLoading"
import { ErrorNotif } from "../../../../Utils/FeedbackHandler/FeedbackHandler"

const SkinsContainer = ({
  wikiid,
  totalSkins,
  enabledSkins,
  setEnabledSkins,
  defaultSkin,
  setDefaultSkin
}) => {
  const [skinLoading, setSkinLoading] = useState(false)
  const [error, seterror] = useState("")

  const mediawiki_skin_endpoint = "https://www.mediawiki.org/wiki/Skin:"

  function changeDefaultSkin(default_skin) {
    seterror("")
    setSkinLoading(true)
    axios
      .post(`wikis/${wikiid}/default-skin/${default_skin}`, "")
      .then((response) => {
        if (
          response.data.indexOf(default_skin) !== -1 &&
          response.data.indexOf("\n") === -1
        ) {
          setDefaultSkin(default_skin)
        }
        setSkinLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setSkinLoading(false)
        if (error.response && error.response.status === "401") {
          window.location.reload()
        } else {
          seterror("Something went wrong. Please try again.")
        }
      })
  }

  function addSkin(name) {
    seterror("")
    setSkinLoading(true)
    axios
      .post(`wikis/${wikiid}/skins/${name}`, "")
      .then(() => {
        setEnabledSkins([...enabledSkins, name])
        setSkinLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setSkinLoading(false)
        if (error.response.status === "401") {
          window.location.reload()
        } else {
          seterror("Something went wrong. Please try again.")
        }
      })
  }

  // Takes the pretty name of a skin as a parameter and opens the link associated with that skin
  function openLink(name) {
    window.open(mediawiki_skin_endpoint + name)
  }

  function deleteSkin(name) {
    seterror("")
    setSkinLoading(true)

    if (name === defaultSkin) {
      setSkinLoading(false)
      seterror("This skin is currently the default skin of your wiki. It can't be removed until a "
        + "different skin is selected as your wiki's new default skin.")
      return
    } else {
      console.log(name + " !+ " + defaultSkin)
    }

    const newList = enabledSkins.filter((skin) => skin !== name)
    axios
      .delete(`wikis/${wikiid}/skins/${name}`)
      .then(() => {
        setEnabledSkins(newList)
        setSkinLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setSkinLoading(false)

        if (error.response.status === "401") {
          window.location.reload()
        } else {
          seterror("Something went wrong. Please try again.")
        }
      })
  }

  return (
    <FadeIn>
      {skinLoading && <ChangesLoading />}

      <div className="skins-container">
        {error && <ErrorNotif error={error} />}

        <span>Manage Your Skins</span>
        <p>
          If the skin you wish to install is not an option below,{" "}
          <a href="https://www.mywikis.com/contact">
            please contact our support team.
          </a>{" "}
          They will assist you with installing the skin.
        </p>
        <div className="skin-buttons-wrapper">
          {totalSkins.skins_list.map((skin, index) => (
            <div>
              <SkinCard
              key={index}
              name={skin}
              pName={totalSkins.skins_data[skin].pretty_name}
              enabled={enabledSkins.indexOf(skin) > -1}
              isPreinstalled={totalSkins.skins_data[skin].preinstalled}
              addSkin={addSkin}
              deleteSkin={deleteSkin}
              openLink={openLink}
             />
              <div className="column">
                <img src={require("../../../../Images/" + skin + ".png").default} alt={skin}/>
              </div>
            </div>
           ))}
        </div>
        <div>
          <br></br>
          <span>Select Your Default Skin</span>
          <p>
            The default skin will be displayed to users who are not signed in,{" "}
            as well as to logged-in users who haven't changed their skin preferences.
          </p>
          <DropdownDefaultSkin
            wikiid={wikiid}
            name="Default Skin Dropdown"
            type="default-skin"
            enabledSkinsOptions={enabledSkins}
            changeDefaultSkin={changeDefaultSkin}
            defaultSkin={defaultSkin}
            totalSkinsData={totalSkins.skins_data}
          />
        </div>
      </div>
    </FadeIn>
  )
}

export default SkinsContainer
