import FadeIn from "react-fade-in";
import "./FeedbackHandler.css";

export const SuccessNotif = ({ message, width }) => (
  <FadeIn>
    <div className="changes-made feedback-notif" style={{ width: `${width}%` }}>
      <i className="fas fa-check-circle"></i>
      {message}
    </div>
  </FadeIn>
);

export const ErrorNotif = ({ error }) => (
  <FadeIn>
    <div className="smthingwentwrong feedback-notif">
      <i className="far fa-times-circle"></i>
      {error}
    </div>
  </FadeIn>
);
