import React, { useState } from "react"
import logo from "../../../logo.svg"
import { BiEnvelope, BiKey } from "react-icons/bi"
import FadeIn from "react-fade-in"
import "./LoginForm.css"
import axios from "axios"
import { setUserSession } from "../../../Utils/Common"
import { TitleComponent } from "../../Other/TitleComponent"

const LoginForm = (props) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const attemptLogin = (e) => {
    e.preventDefault()
    setError(null)
    setLoading(true)

    axios
      .post("/auth/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        setUserSession(response.data.token, response.data.user)
        setLoading(false)
        props.history.push("/portal")
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
        if (!error.response) {
          setError("Something went wrong.")
        } else {
          setError(error.response.data.detail)
        }
      })
  }

  return (
    <div className="form">
      <TitleComponent title="Login | MyWikis Self Service" />
      <FadeIn>
        <form onSubmit={attemptLogin}>
          <img className="login-logo" src={logo} alt="" />
          <h2>MyWikis Self Service</h2>
          <div className="form-inner">
            {error && (
              <div className="alert-box">
                <div className="alert-content">
                  <h3>{error}</h3>
                  {error !== "Something went wrong." &&
                    (email.length === 0 || password.length === 0
                      ?
                      <p>
                        Enter your email and password
                      </p>
                      : !email.includes("@")
                      ?
                      <p>
                        Please use the same email address and password
                        you use to log in to the MyWikis Client Panel at
                        <a href={`https://${process.env.REACT_APP_CLIENT_PANEL_DOMAIN ?? "panel.mywikis.com"}`}>panel.mywikis.com</a>.
                      </p>
                      :
                      <p>
                        Try again or{" "}
                        <a href={`https://${process.env.REACT_APP_CLIENT_PANEL_DOMAIN ?? "panel.mywikis.com"}/index.php?rp=/password/reset`}>reset your password</a>.
                      </p>
                    )}
                </div>
              </div>
            )}

            <div className="form-group">
              <div className="text-field-icon">
                <BiEnvelope size="20px" />
              </div>
              <input
                type="username"
                name="email"
                id="email"
                placeholder="Your E-Mail"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>

            <div className="form-group">
              <div className="text-field-icon">
                <BiKey size="20px" />
              </div>

              <input
                name="password"
                type={isPasswordShown ? "text" : "password"}
                placeholder="Your Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              {password !== "" && (
                <i
                  className={`fa ${isPasswordShown ? "fa-eye" : "fa-eye-slash"
                    }`}
                  onClick={() => setIsPasswordShown(!isPasswordShown)}
                ></i>
              )}
            </div>
            <input
              className="bn632-hover bn26"
              type="submit"
              value={loading ? "Loading..." : "Login"}
            />
          </div>
        </form>
        <footer>
          <p className="copyright-text">© 2020-{new Date().getFullYear()} MyWikis LLC.</p>
        </footer>
      </FadeIn>
    </div>
  )
}

export default LoginForm
