import { useState } from "react";
import "./ResetPass.css";
import FadeIn from "react-fade-in";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import { useEffect } from "react";
import axios from "axios";
import { SpinnerWithLogo } from "../../WikisSpinner/WikisSpinner";
import ChangesLoading from "../../Other/ChangesLoading";
import { SuccessNotif } from "../../../Utils/FeedbackHandler/FeedbackHandler";

const ResetPass = () => {
  const handleReset = (e) => {
    e.preventDefault();
    if (newPass !== reenterPass) return;

    setsubmitLoading(true);
    axios
      .post(`wikis/${wikiid}/users/${username}/password`, null, {
        params: {
          mediawiki_password: newPass,
        },
      })
      .then((response) => {
        setsubmitLoading(false);
        setchangesMade(true);
        setUsername("");
        setNewPass("");
        setReenterPass("");
      })
      .catch((error) => {
        setsubmitLoading(false);
        console.error(error.response);
        console.log();
        if (!error.response || error.response.status === 404) {
          history.push("/portal");
        } else if (error.response.status === 500) {
          seterror(error.response.data.detail);
          setNewPass("");
          setReenterPass("");
        }
        // window.location.reload();
      });
  };

  const [username, setUsername] = useState("");
  const [newPass, setNewPass] = useState("");
  const [reenterPass, setReenterPass] = useState("");

  const [wikiName, setwikiName] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const [error, seterror] = useState("");
  const [changesMade, setchangesMade] = useState(false);
  const { wikiid } = useParams();

  let history = useHistory();

  useEffect(() => {
    axios
      .get(`wikis/${wikiid}/name`)
      .then((response) => {
        setwikiName(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (!error.response || error.response.status === 404) {
          history.push("/login");
        } else window.location.reload();
      });
  }, [history, wikiid]);

  if (loading) return <SpinnerWithLogo />;

  return (
    <>
      {submitLoading && <ChangesLoading />}

      <Navbar />
      <div className="reset-pass-form">
        <FadeIn>
          {changesMade && (
            <SuccessNotif
              message={`Password successfully changed.`}
              width="100"
            />
          )}
          <form onSubmit={handleReset} required autocomplete="off">
            <h3>Reset Password</h3>
            <p>
              You are reseting a password for a wiki user on{" "}
              <span>
                {wikiName} (ID: {wikiid}).
              </span>
            </p>
            {error && <ErrorNotif error={error} />}
            <label>
              Wiki ID
              <input
                disabled
                type="text"
                name="wikiid"
                value={wikiid}
                onChange={(e) => setUsername(e.target.value)}
              />
            </label>
            <label>
              Wiki Username
              <input
                type="text"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onSelect={() => {
                  seterror("");
                  setchangesMade(false);
                }}
              />
            </label>

            <label>
              New Password
              <input
                autocomplete="new-password"
                type="password"
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
                onSelect={() => {
                  seterror("");
                  setchangesMade(false);
                }}
                required
              />
            </label>
            <label>
              Re-enter New Password
              <input
                type="password"
                autocomplete="false"
                value={reenterPass}
                onChange={(e) => setReenterPass(e.target.value)}
                onSelect={() => {
                  seterror("");
                  setchangesMade(false);
                }}
                required
              />
            </label>
            {newPass !== reenterPass && reenterPass !== "" && (
              <p style={{ color: "red" }}>Passwords do not match</p>
            )}
            <input type="submit" value="Reset Password" />
          </form>
          <footer>
            <p className="copyright-text">© 2020-{new Date().getFullYear()} MyWikis LLC.</p>
          </footer>
        </FadeIn>
      </div>
    </>
  );
};

const ErrorNotif = ({ error }) => (
  <div className="error-wrapper" transitionDuration={300}>
    <span>{error}</span>
  </div>
);

export default ResetPass;
