import "./ExtensionCardAV.css";
import Popup from "../DisallowedPopup/Popup";
import React, { useLayoutEffect, useState } from "react";
import ExtensionIcon from "../ExtensionIcon/ExtensionIcon";
import { BiInfoCircle } from "react-icons/bi";

const ExtensionCardDisallowed = ({
  name,
  pName,
  icon,
  description,
  openLink,
  generateExtensionMWOPage
}) => {
  // keep track of window width
  const [width, setwidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className="extension-card-disallowed">
      {/* <button
          className="ext-link-button"
          onClick={() => openLink(name)}
        >
          <BiInfoCircle />
      </button> */}
        <div className="card-details-container">
          <ExtensionIcon icon_raw={icon} disallowed={true} />
          <div className="extension-info">
            <h4>{pName} <a href={generateExtensionMWOPage(name)} target="_blank" rel="noreferrer"><BiInfoCircle /></a></h4>
            <p>{description}</p>
          </div>
        </div>
    </div>
  );
};

export default ExtensionCardDisallowed;
