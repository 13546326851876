import { React, useEffect, useState } from "react";
import "./ConfigCard.css";
const ConfigCard = ({
  enabledConfigs,
  name,
  pName,
  id,
  handleCheckboxChange,
  enabled,
}) => {
  const [isChecked, setIsChecked] = useState(enabled);

  const toggleCheckboxChange = () => {
    setIsChecked(!isChecked);
    handleCheckboxChange(name);
  };

  return (
    <label
      htmlFor={id}
      className={enabled ? "config-card cfg-enabl" : "config-card"}
    >
      <input
        type="checkbox"
        checked={enabled}
        id={id}
        onChange={toggleCheckboxChange}
      />
      <span className="checkbox-icon"></span>
      <small className="config-label">{pName}</small>
    </label>
  );
};

export default ConfigCard;
