import ExtensionCardAV from "./AvailableExtensions/ExtensionCardAV";
import ExtensionCardDisallowed from "./AvailableExtensions/ExtensionCardDisallowed";
import ExtensionCard from "./ExtensionCard/ExtensionCard";
import Popup from "./DisallowedPopup/Popup";
import "./ExtensionContainer.css";
import { useEffect, useState } from "react";
import { BiSad } from "react-icons/bi";
import axios from "axios";
import { ClipSpinner } from "../../../WikisSpinner/WikisSpinner";
import FadeIn from "react-fade-in";
import React from "react";
import Modal from "../../Portal/Modal/Modal"

import ChangesLoading from "../../../Other/ChangesLoading";
import { ErrorNotif } from "../../../../Utils/FeedbackHandler/FeedbackHandler";

const ExtensionContainer = ({
  wikiid,
  wikiplan,
  totalExtensions,
  enabledExtensions,
  setEnabledExtensions,
}) => {
  const [extensionLoading, setextensionLoading] = useState(false);
  const [error, seterror] = useState("");
  const [showFieldsModal, setShowFieldsModal] = useState(false)
  const [extName, setExtName] = useState(false)
  const [formData, setFormData] = useState({})

  const [searchTerm, setSearchTerm] = useState("");

  const mediawiki_extensions_endpoint = "https://www.mediawiki.org/wiki/Extension:";

  const description =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos similique facere pariatur quae iure sequi non a et eligendi nobis.";

  function generateExtensionMWOPage(name) {
    return mediawiki_extensions_endpoint + name;
  }

  // Takes the name of an extension as a parameter and opens the link associated with that extension
  function openLink(name) {
    window.open(generateExtensionMWOPage(name));
  }

  function getExtSettings(extName) {
    setextensionLoading(true);
    axios
    .get(`wikis/${wikiid}/extensions/${extName}/settings`)
    .then((response) => {      
      
      let data = {}
      for (const [key, val] of Object.entries(response.data)) {
        data[key] = val['value']
      }
      setFormData(data);

      setextensionLoading(false);
      setShowFieldsModal(true);
    })
    .catch((error) => {
      setextensionLoading(false);
      setShowFieldsModal(true);
    });
  }

  function addExtension(name) {
    setextensionLoading(true);
    seterror("");

    axios
    .post(`wikis/${wikiid}/extensions/${name}`, "")
    .then(() => {
      setEnabledExtensions([...enabledExtensions, name]);
      setextensionLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setextensionLoading(false);
      if (error.response.status === "401") window.location.reload();
      else seterror("Something went wrong. Please try again.");
    });
  }

  function deleteExtension(name) {
    seterror("");
    setextensionLoading(true);

    const newList = enabledExtensions.filter((ext) => ext !== name);
    axios
      .delete(`wikis/${wikiid}/extensions/${name}`)
      .then((response) => {
        setEnabledExtensions(newList);
        setextensionLoading(false);
      })
      .catch((error) => {
        setextensionLoading(false);
        if (error.response.status === "401") window.location.reload();
        else seterror("Something went wrong. Please try again.");
      });
  }

  function changeSettings(event) {
    event.preventDefault()
    seterror("")
    setextensionLoading(true)

    let dataToSend = {}
    for (const [key, val] of Object.entries(formData)) {
      dataToSend[key] = {
        value: val
      }
    }

    axios
      .put(`wikis/${wikiid}/extensions/${extName}/settings`, dataToSend)
      .then((response) => {
        setextensionLoading(false)
        setShowFieldsModal(false)
        document.body.classList.remove('active-modal')
      })
      .catch((error) => {
        console.error(error);
        setextensionLoading(false);
        document.body.classList.remove('active-modal')
        if (error.response.status === "401") window.location.reload();
        else seterror("Something went wrong. Please try again.");
      });
  }

  function handleFormValuesOnChange(field, value) {
    setFormData(currentData => ({
      ...currentData,
      [field]: value
    }))
  }

  function closeSettingsModal() {
    setShowFieldsModal(false)
    document.body.classList.remove('active-modal')
  }

  return (
    <FadeIn>
      {extensionLoading && <ChangesLoading />}
      {error && <ErrorNotif error={error} />}

      <div className="extensions-container">
        <label>Manage Your Extensions</label>
        <section className="enabled-extensions-container">
          <h3>
            Enabled <span>{enabledExtensions.length}</span>
          </h3>
          {!enabledExtensions.length && <ExtensionEmptyCard />}
          {enabledExtensions.map((extension, index) => (
            <ExtensionCard
              key={index}
              pName={totalExtensions.extensions_data[extension].pretty_name}
              name={extension}
              icon={totalExtensions.extensions_data[extension].icon}
              description={
                totalExtensions.extensions_data[extension].description
              }
              setExtName={setExtName}
              settings={totalExtensions.extensions_data[extension].settings}
              setShowFieldsModal={setShowFieldsModal}
              getExtSettings={getExtSettings}
              deleteExtension={deleteExtension}
              openLink={openLink}
              generateExtensionMWOPage={generateExtensionMWOPage}
            />
          ))}
        </section>
        <section className="available-extensions-container">
          <h2>Available Extensions</h2>
          <input
            className="extensions-search-bar"
            type="text"
            placeholder="Search..."
            spellCheck="false"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          {totalExtensions.extensions_list
            .filter((extension) =>
              totalExtensions.extensions_data[extension].pretty_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((extension, index) =>
              totalExtensions.extensions_data[extension].allowed_plans.length > 0 &&
              totalExtensions.extensions_data[extension].allowed_plans
                  .indexOf(wikiplan.toLowerCase()) === -1 ? (
                <Popup
                  content="This extension can be installed on a higher plan."
                  child={
                    <ExtensionCardDisallowed
                      name ={extension}
                      pName={
                        totalExtensions.extensions_data[extension].pretty_name
                      }
                      icon={totalExtensions.extensions_data[extension].icon}
                      description={
                        totalExtensions.extensions_data[extension].description
                      }
                      openLink={openLink}
                      generateExtensionMWOPage={generateExtensionMWOPage}
                    />
                  }
                />
              ) : totalExtensions.extensions_data[extension].preinstalled ? (
                <ExtensionCardAV
                  name ={extension}
                  pName={
                    totalExtensions.extensions_data[extension].pretty_name
                  }
                  icon={totalExtensions.extensions_data[extension].icon}
                  enabled={true}
                  description={
                    totalExtensions.extensions_data[extension].description
                  }
                  settings={totalExtensions.extensions_data[extension].settings}
                  openLink={openLink}
                  generateExtensionMWOPage={generateExtensionMWOPage}
                />
              ) : (
                <ExtensionCardAV
                  key={index}
                  pName={totalExtensions.extensions_data[extension].pretty_name}
                  name={extension}
                  icon={totalExtensions.extensions_data[extension].icon}
                  enabled={enabledExtensions.indexOf(extension) > -1}
                  description={
                    totalExtensions.extensions_data[extension].description
                  }
                  setExtName={setExtName}
                  settings={totalExtensions.extensions_data[extension].settings}
                  setShowFieldsModal={setShowFieldsModal}
                  getExtSettings={getExtSettings}
                  addExtension={addExtension}
                  openLink={openLink}
                  generateExtensionMWOPage={generateExtensionMWOPage}
                />
              )
            )}
            {showFieldsModal && <Modal onHide={() => closeSettingsModal()}
            title='Edit Extension Settings'>
            <br></br>
            <form onSubmit={changeSettings}>
              {Object.keys(totalExtensions.extensions_data[extName].settings).map((setting, index) => (
                <div>
                  <label>{totalExtensions.extensions_data[extName].settings[setting].title}</label>
                  {totalExtensions.extensions_data[extName].settings[setting].type === 'string' ?
                  (
                    <input
                      className="input-box"
                      key={index}
                      name={setting}
                      value={formData[setting]}
                      onChange={e => handleFormValuesOnChange(setting, e.target.value)}
                      type="text"
                    />
                  )
                  : totalExtensions.extensions_data[extName].settings[setting].type === 'bool' ?
                  (
                    <input
                      className="input-box"
                      key={index}
                      name={setting}
                      checked={formData[setting] === true || formData[setting] === 'True'}
                      onChange={e => handleFormValuesOnChange(setting, e.target.checked === true)}
                      type="checkbox"
                    />
                  ) : `Invalid type ${totalExtensions.extensions_data[extName].settings[setting].type}`
                  }
                </div>
              ))}
               <input
                    type="submit"
                    value="Save"
                />
              </form>
            </Modal>}
        </section>
      </div>
    </FadeIn>
  );
};

const ExtensionEmptyCard = () => {
  return (
    <div className="empty-display">
      <BiSad />
      <p>You haven't added any extensions yet</p>
    </div>
  );
};

export default ExtensionContainer;
