import React, { useState } from "react";
import "./Popup.css";

const Popup = ({ content, child }) => {
  const [active, setActive] = useState(false);

  const showPopup = () => {
    setActive(true);
  };

  const hidePopup = () => {
    setActive(false);
  };

  return (
    <div
      className="popup-wrapper"
      onMouseEnter={showPopup}
      onMouseLeave={hidePopup}
    >
      {child}
      {active && <div className={`popup-box`}>{content}</div>}
    </div>
  );
};

export default Popup;
