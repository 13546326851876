import WikiCard from "./WikiCard/WikiCard";
import "./Portal.css";
import FadeIn from "react-fade-in";
import { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "../../../Utils/Common";
import Navbar from "../../Navbar/Navbar";
import { SpinnerWithLogo } from "../../WikisSpinner/WikisSpinner";
import Modal from "./Modal/Modal";
import React from "react";

const Portal = ({ Logout, email }) => {
  const [wikis, setWikis] = useState([])
  const [loading, setloading] = useState(true)
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);


  useEffect(() => {
    let header = {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    axios
      .get("/wikis/", header)
      .then((response) => {
        setWikis(response.data.wikis);
        setloading(false);
      })
      .catch((error) => {
        setWikis([])
      });
  }, []);

  if (loading) {
    return <SpinnerWithLogo />;
  }

  return (
    <div>
      {showPrivacyModal && <Modal onHide={() => setShowPrivacyModal(false)} title='Notice'>
        To ensure the security of your wiki, your wiki’s privacy cannot be changed on MyWikis Self Service.
        To change your wiki’s privacy level, please open a support ticket and specify which privacy level you would like.
        You can find out the privacy levels we offer at <a href="https://www.mywikis.com/privacy">mywikis.com/privacy</a>.
      </Modal>}
      <Navbar />
      <div className="content">
        <div className="cards-title">
          <h1>Owned Wikis</h1>
          <hr />
        </div>
        <FadeIn>
          <div className="cards-container">
            {wikis.map((wiki, index) => (
              <WikiCard
                wikiName={wiki.name}
                wikiID={wiki.wikiid}
                plan={wiki.plan}
                productID={wiki.pid}
                key={index}
                setShowPrivacyModal={setShowPrivacyModal}
              />
            ))}
          </div>
        </FadeIn>
        <footer>
          <p className="copyright-text">© 2020-{new Date().getFullYear()} MyWikis LLC.</p>
        </footer>
      </div>
    </div>
  );
};

export default Portal
