import { useState } from "react";
import "./ExtensionIcon.css";

const ExtensionIcon = ({ icon_raw,disallowed, }) => {
  const interpretIcon = () => {
    const icon_split = icon_raw.split("::");

    return {
      type: icon_split[0],
      string: icon_split[1],
    };
  };

  const icon = interpretIcon();
  return (
    <div className={disallowed ? `ext-icon-wrapper ext-icon-disallowed-${icon.type}` : `ext-icon-wrapper ext-icon-${icon.type}`}>
    {/* <div className={`ext-icon-wrapper ext-icon-${icon.type}`}> */}
      {icon.type === "fontawesome" && <i className={icon.string}></i>}
      {icon.type === "image" && <img src={icon.string} href="extension icon" />}
      {icon.type === "initial" && <h1>{icon.string}</h1>}
    </div>
  );
};

export default ExtensionIcon;
