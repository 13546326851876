import "../../../Portal/WikiCard/DropdownButton/DropdownButton.css"
import React, { useState, useEffect } from "react"

const DropdownDefaultLanguage = ({
  type,
  languagesData,
  changeDefaultLanguage,
  defaultLanguage,
  isExternal,
}) => {

  function handleChange(e) {
    changeDefaultLanguage(e.target.value)
  }

  return (
    <div id="dropdown-container">
      <span className="default-gap"></span>
      <div className="dropdown-gap"></div>
      <div className="select-wrapper">
        <select className="select-box" value={defaultLanguage} onChange={handleChange}>
          {Object.keys(languagesData).map((language, index) => (
            <option
              className={type}
              key={index}
              value={language}
            >
              {languagesData[language].pretty_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default DropdownDefaultLanguage