import React from 'react'
import "./Modal.css"

const Modal = props => {

  return (
    <div className="modal">
      <div className="overlay"></div>
      <div className="modal-content">
        <h2>{props.title}</h2>
        <p>{props.children}</p>
        <button className="close-modal" onClick={props.onHide}>
          CLOSE
        </button>
      </div>
    </div>
  )
}

export default Modal