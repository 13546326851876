import React from "react"
import "../../../Portal/WikiCard/DropdownButton/DropdownButton.css"

const DropdownDefaultSkin = ({
  wikiid,
  name,
  type,
  enabledSkinsOptions,
  changeDefaultSkin,
  defaultSkin,
  totalSkinsData,
  isExternal,
}) => {
  function handleChange(e) {
    changeDefaultSkin(e.target.value);
  }

  return (
    <div id="dropdown-container">
      <span className="default-gap"></span>
      <div className="dropdown-gap"></div>
      <div className="select-wrapper">
        <select className="select-box" value={defaultSkin} onChange={handleChange}>
          {Object.keys(totalSkinsData).map((skin, index) => (
            totalSkinsData[skin].preinstalled &&
              (
                <option
                  className={type}
                  key={index}
                  value={skin}
                >
                  {totalSkinsData[skin].pretty_name}
                </option>
              )
          ))}
          {enabledSkinsOptions.map((skin, index) => (
            <option
              className={type}
              key={index}
              value={skin}
            >
              {totalSkinsData[skin].pretty_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default DropdownDefaultSkin
